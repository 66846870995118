import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const VampirLiftPRPTherapie = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Vampir Lift / PRP-Therapie"
			noSlider
			{...props}
		>
			<p>
				Hierbei handelt es sich um eine Therapie, bei der eine geringe
				Menge patienteneigenes Blut zentrifugiert wird. Es entsteht eine
				Teilflüssigkeit Ihres Blutes ohne roten Farbstoff, jedoch
				angereichert mit Thrombozyten und Wachstumsfaktoren, dem
				Plateled Rich Plasma, plättchenreiches Plasma.
			</p>
			<p>
				Diese Flüssigkeit wird unter die Haut injiziert und führt zu
				einer Aktivierung Ihrer Haut durch die Anregung hauteigener
				Regenerationsprozesse. Die Haut gewinnt an Glanz, Frische und
				Spannkraft und Fältchen werden reduziert.
			</p>
			<p>
				Durch die Verwendung von aufbereitendem Eigenblut zur
				Hautverbesserung hat sich der Begriff Vampir-Lift entwickelt.
			</p>
			<p>
				Das gewonnene Plasma kann per Injektion unter die Haut gebracht
				werden. Alternativ bietet sich ein Micro-Needling mit
				anschließendem auftragen des Plasmas auf die Haut. Daneben
				bestehen weitere Behandlungsansätze mit der PRP-Therapie zum
				Beispiel bei Haarausfall.
			</p>

			<Questions title="Vampir Lift / PRP-Therapie" prefix="um" />
		</Layout>
	);
};

export default VampirLiftPRPTherapie;
